<style lang="scss">

  .full-timeline-index {
    .container-timeline {
      width: 100%;
      margin: 50px auto;
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        height: 100%;
        background: #FBD784;
        z-index: 1
      }

      .timeline-block {
        width: -webkit-calc(50% + 8px);
        width: -moz-calc(50% + 8px);
        width: calc(50% + 8px);
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        justify-content: space-between;
        clear: both;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease-in-out;

        &-right {
          float: right;
        }

        &-left {
          float: left;
          direction: rtl;
          text-align: initial;
        }

        &-in-view {
          transform: none;
          visibility: visible;
          opacity: 1;
        }

        .marker {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid #F5F7FA;
          background: #FBD784;
          margin-top: 10px;
          z-index: 9999
        }

        .timeline-content {
          width: 95%;
          padding: 0 15px;
          color: #666;
          img {
            max-width: 400px;
          }
          h3 {
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 25px;
            font-weight: 500;
            color: #FFFFFF;
          }

          span {
            font-size: 15px;
            font-weight: bold;
            color: #FBD784;
          }

          p {
            font-size: 14px;
            line-height: 1.5em;
            word-spacing: 1px;
            color: #FFFFFF;
            direction: initial;
          }
          a {
              color: #FFA343;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .container-timeline {
        &:before {
          left: 8px;
          width: 2px;
        }

        .timeline-block {
          width: 100%;
          margin-bottom: 30px;

          &-right {
            float: none;
          }

          &-left {
            float: none;
            direction: ltr;
          }
        }
      }
    }
  }

</style>

<template lang="pug">

  .full-timeline-index
    .container-timeline
      template(v-for="(year, index) in timeline")
        .timeline-block(:key="index" :class="side(index)")
          .marker
          .timeline-content
            h3 {{ year.ano }}
            span {{ year.titulo }}
            div
              VueMarkdown(:anchorAttributes={ target: '_blank', rel: 'noopener noreferrer nofollow'}) {{ year.descricao }}

</template>

<script>
import VueMarkdown from 'vue-markdown';

export default {
  name: 'FullTimeline',

  props: {
    timeline: { type: Array, required: true }
  },

  data() {
    return {
      timelineItems: []
    };
  },

  updated() {
    this.timelineItems = document.querySelectorAll('.timeline-block');

    window.addEventListener('load', this.callbackFunc);
    window.addEventListener('resize', this.callbackFunc);
    window.addEventListener('scroll', this.callbackFunc);
  },

  components: {
    VueMarkdown
  },

  methods: {
    side(index) {
      return (index % 2 === 0) ? 'timeline-block-right' : 'timeline-block-left';
    },

    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();

      return (
        rect.top >= 0
        && rect.left >= 0
        && rect.bottom
          <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },

    callbackFunc() {
      for (let i = 0; i < this.timelineItems.length; i += 1) {
        if (this.isElementInViewport(this.timelineItems[i])) {
          this.timelineItems[i].classList.add('timeline-block-in-view');
        }
      }
    }
  }
};

</script>
